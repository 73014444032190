import React, { useEffect, useState } from 'react'
import {
  Container,
  Nav,
  Navbar,
  Col,
  Row,
  Button,
  Form,
  FormControl,
  Dropdown,
} from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";
import SchoolSideMenu from "./SchoolSideMenu";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { auto } from '@popperjs/core';
import { FecthData } from '../../Services/FetchData';
import { NotificationManager } from 'react-notifications';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import GraphUI from '../common/GraphUI';
import { getMaskedText, getWeekOfMonth, removeField } from '../../helpers/utils';
import NotificationUI from '../common/NotificationUI';
import { getResourceUrl } from '../../Services/CommanFunctions';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'bottom'
    },
    title: {
      display: true,
      text: '',
    },
  },
};

const labels = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];

const data = {
  labels,
  datasets: [
    {
      label: 'Algebra',
      data: {
        MONDAY: 10,
        TUESDAY: 20,
        WEDNESDAY: 32,
        THURSDAY: 24,
        FRIDAY: 55,
        SATURDAY: 42,
        SUNDAY: 5,
      },
      backgroundColor: [
        '#914CE9',
      ],
    },
    {
      label: 'Chemistry',
      data: {
        MONDAY: 42,
        TUESDAY: 20,
        WEDNESDAY: 45,
        THURSDAY: 5,
        FRIDAY: 46,
        SATURDAY: 13,
        SUNDAY: 10,
      },
      backgroundColor: [
        '#FEB41C',
      ],
    },
    {
      label: 'Physics',
      data: {
        MONDAY: 10,
        TUESDAY: 20,
        WEDNESDAY: 32,
        THURSDAY: 24,
        FRIDAY: 59,
        SATURDAY: 42,
        SUNDAY: 5,
      },
      backgroundColor: [
        '#D0202C',
      ],
    },
    {
      label: 'Geometry',
      data: {
        MONDAY: 42,
        TUESDAY: 22,
        WEDNESDAY: 54,
        THURSDAY: 68,
        FRIDAY: 12,
        SATURDAY: 5,
        SUNDAY: 30,
      },
      backgroundColor: [
        '#73913E',
      ],
    },
  ],
};

ChartJS.register(Tooltip, Legend);

function SchoolStudentStat(props) {
  const user = JSON.parse(localStorage.getItem('user'))
  const student = props.location.state
  const [interval, setInterval] = useState('weekly')
  const [labels, setLabels] = useState(['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'])
  const [datasets, setDatasets] = useState(data.datasets)
  const colorObj = {
    Algebra: '#914CE9',
    Chemistry: '#FEB41C',
    Physics: '#D0202C',
    Geometry: '#73913E',
    Biology: '#d32833',
    Calculus: '#7b2cbf'
  }
  const [state, setState] = useState(data)
  const [statistics, setStatistics] = useState()
  const [school, setSchool] = useState()
  const selectedDay = val => {
    console.log(val);
  };

  const getStudentProgress = () => {
    FecthData.getData('student/get_student_progress/' + student?._id + '?interval=' + interval).then(
      (data) => {
        var labels1 = interval == 'weekly' ? ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'] : ['WEEK 1', 'WEEK 2', 'WEEK 3', 'WEEK 4', 'WEEK 5'];
        let arr1 = []
        data?.data.map(pr => {
          var obj = {
            label: pr?._id?.subject_name,
            data: interval == 'weekly' ? {
              MONDAY: new Date(pr?._id?.date).getDay() == 1 ? (pr.total_hours / 60).toFixed(2) : 0,
              TUESDAY: new Date(pr?._id?.date).getDay() == 2 ? (pr.total_hours / 60).toFixed(2) : 0,
              WEDNESDAY: new Date(pr?._id?.date).getDay() == 3 ? (pr.total_hours / 60).toFixed(2) : 0,
              THURSDAY: new Date(pr?._id?.date).getDay() == 4 ? (pr.total_hours / 60).toFixed(2) : 0,
              FRIDAY: new Date(pr?._id?.date).getDay() == 5 ? (pr.total_hours / 60).toFixed(2) : 0,
              SATURDAY: new Date(pr?._id?.date).getDay() == 6 ? (pr.total_hours / 60).toFixed(2) : 0,
              SUNDAY: new Date(pr?._id?.date).getDay() == 7 ? (pr.total_hours / 60).toFixed(2) : 0,
            } : {
              'WEEK 1': getWeekOfMonth(pr?._id?.date) == 0 ? (pr.total_hours / 60).toFixed(2) : 0,
              'WEEK 2': getWeekOfMonth(pr?._id?.date) == 1 ? (pr.total_hours / 60).toFixed(2) : 0,
              'WEEK 3': getWeekOfMonth(pr?._id?.date) == 2 ? (pr.total_hours / 60).toFixed(2) : 0,
              'WEEK 4': getWeekOfMonth(pr?._id?.date) == 3 ? (pr.total_hours / 60).toFixed(2) : 0,
              'WEEK 5': getWeekOfMonth(pr?._id?.date) == 4 ? (pr.total_hours / 60).toFixed(2) : 0,
            },
            backgroundColor: [
              colorObj[`${pr?._id?.subject_name}`]
            ],
          }
          arr1.push(obj)
        })
        setLabels(labels1)
        setDatasets(arr1)
      }
    )
  }
  const getStudentStatistics = () => {
    FecthData.getData('student/get_student_progress_count/' + student?._id).then(
      (data) => {
        setStatistics(data?.data)

      }
    )
  }

  useEffect(() => {
    FecthData.getData('school/get_school_by_id/' + student?.school_id).then(
      (data) => setSchool(data?.data),
      (error) => NotificationManager.error(error.toString(), 'error!', 2000)
    )
    getStudentStatistics()
  }, [])
  useEffect(() => {
    getStudentProgress()
  }, [interval])
  let history = useHistory()
  const logout = () => {
    localStorage.clear()
    history.push('/school')
  }
  return (
    <>
      <section style={{ height: '100%', width: 'auto' }}>
        <div className="dashboard-wrap">
          <SchoolSideMenu />
          <div className="middle">
            <Col md={12}>
              <Form.Group className="form-group mb-2">
                <Form.Control type="text" placeholder="Search" className="search-input" />
                <Button variant="primary" type="submit" className="search-btn">
                  <img src="images/search-icon.png" alt="" width="22" />
                </Button>
              </Form.Group>
            </Col>
            <Row>
              <Col md={12}>
                <div className="card">
                  <Row>
                    <Col md={9} className="mt-2">
                      <div className="d-flex align-items-center">
                        <div className="school-logo-small">
                          <img src={student.gender_avatar?.icon_image?getResourceUrl(student.gender_avatar?.icon_image):"images/no_img.png"} alt="" />
                        </div>
                        <div className="ms-1">
                          <h4 className="text-muted mb-0">ID: {student?.student_code}</h4>
                          <h5 className="text-muted mb-0">Grade: {student?.grade_id ? student?.grade_id.title : null} | {school?.school_name}</h5>
                        </div>
                      </div>
                    </Col>
                    <Col md={3} className="mt-2">
                      <div className="tutor-action">
                        <ul className="justify-content-end">
                          <li className="active"><a href="javascript:void(0)"><i className="fa fa-comment-o"></i>
                          </a>
                          </li>
                        </ul>
                      </div>
                    </Col>
                    <GraphUI state={{ labels: labels, datasets: datasets }} statistics={statistics} setInterval={setInterval} />
                    {/* <Col md={4}>
                      <div className="card bg-purple progress-box">
                        <div className="card-text text-white">
                          <img src="images/hat-icon.png" alt="" width="70" />
                          <h4>Scheduled Classes</h4>
                        </div>
                        <div className="card-big-text">
                          <h5 className="count">15</h5>
                        </div>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="card bg-danger progress-box">
                        <div className="card-text text-white">
                          <img src="images/book-icon.png" alt="" width="58" />
                          <h4>Assignments Completed</h4>
                        </div>
                        <div className="card-big-text">
                          <h5 className="count">07</h5>
                        </div>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="card bg-success progress-box">
                        <div className="card-text text-white">
                          <img src="images/timer-icon.png" alt="" width="34" />
                          <h4>Time SPENT On Dasya</h4>
                        </div>
                        <div className="card-big-text">
                          <h5 className="count">14 <small>HRS</small></h5>
                        </div>
                      </div>
                    </Col> */}
                  </Row>
                  {/* <h5 className="sub-heading mt-4 text-muted d-flex justify-content-between">No. Of Hrs Spent Subject Wise
                    <Form.Group style={{ minWidth: 120 }}>
                      <Form.Select>
                        <option>Weekly</option>
                        <option>Monthly</option>
                      </Form.Select>
                    </Form.Group>
                  </h5>
                  <Bar options={options} data={data} /> */}
                </div>
              </Col>
              {/* <Col md={4}>
                <div className="card">
                  <h5 className="sub-heading text-muted">Current Level</h5>
                  <img src="images/progress-icon.png" alt="" width="150" style={{ margin: auto, marginTop: 10, }} />
                </div>
              </Col>
              <Col md={8}>
                <div className="card">
                  <h5 className="sub-heading text-muted d-flex justify-content-between">Badges & Rewards <span><a className=" text-muted" href="/teacher-review">See All</a></span></h5>
                  <div className="badges">
                    <ul>
                      <li>
                        <img src="images/badge1.png" alt="" width="80" />
                        <h6>SCHEDULE 5+ Sessions</h6>
                      </li>
                      <li>
                        <img src="images/badge2.png" alt="" width="50" />
                        <h6>CHAT WITH TUTOR</h6>
                      </li>
                      <li>
                        <img src="images/badge3.png" alt="" width="64" />
                        <h6>REACH A NEW LEVEL</h6>
                      </li>
                      <li>
                        <img src="images/badge4.png" alt="" width="104" />
                        <h6>COMPLETE AN ASSIGNMENT</h6>
                      </li>
                      <li>
                        <img src="images/badge5.png" alt="" width="58" />
                        <h6>EARN TUTOR BADGE</h6>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col> */}
            </Row>
          </div>
          <div className="right">
            <div className="user-short-text">
            <NotificationUI/>
              {/* <div className="notification-bell">
                <img src="images/bell-icon.png" alt="" width="32" />
                <div className="noti-dot"></div>
              </div> */}
              <div className="user-short-profile">
                <div className="user-avatar">
                  <img src={user?.propic ? getResourceUrl(user?.propic) : "default_logos/no_profile_img.png"} width={'100%'} height={'100%'} alt="" />
                </div>
                <div className="user-short-name">
                  <h5>{user?.school?.school_name}</h5>
                  <p>Status: {user?.status}</p>
                </div>
                <Dropdown >
                  <Dropdown.Toggle drop='none' variant="">

                  </Dropdown.Toggle >
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={logout}
                    // href="#/action"
                    >Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                {/* <div className="drop-down">
                  <img src="images/drop-icon.png" alt="" width="10" />
                </div> */}
              </div>
            </div>
            <div className="card">
              <div className="school-side-details">
                <div className="school-logo-circle">
                  <img src={student.gender_avatar?.icon_image?getResourceUrl(student.gender_avatar?.icon_image):"images/no_img.png"} alt="" />
                </div>
                <div className="school-more-option">
                  <Dropdown>
                    <Dropdown.Toggle>
                      <img src="images/more-icon.png" alt="" width="20" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {/* <Dropdown.Item href="#">View Profile</Dropdown.Item>
                      <Dropdown.Divider /> */}
                      <Dropdown.Item href="#" onClick={() => history.push({ pathname: "/school/user/edit", state: { ...student, user_role: 'student', ...removeField(student?.user_id,['_id','user_role']), user_sf_id: student?.user_id?._id } })}>Edit Account</Dropdown.Item>
                      {/* <Dropdown.Divider /> */}
                      {/* <Dropdown.Item href="#">Delete Account</Dropdown.Item> */}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="school-name">
                  <h5>{getMaskedText(student?.first_name,'name') } {getMaskedText(student?.last_name,'name')}</h5>
                  <h6>Student ID: {student?.student_code}</h6>
                </div>
                <div className="teacher-contact-detail">
                  <ul>
                    <li>
                      <a href="javascript:void(0)">
                        <img src="images/teacher-info1.png" alt="" width="54" />
                      </a>
                    </li>
                    <li className='phone-li'>
                      <a href={`tel:${student?.phone}`} itemRef='ghgf'>
                        <img src="images/teacher-info2.png" alt="" width="54" />
                      </a>
                      {student?.phone ? <span className='show-phone'>{getMaskedText(student?.phone,'phone')}</span> : <span className='show-phone'>Phone Not Available</span>}
                    </li>
                    <li>
                      <a href={`mailto:${student?.email}`}>
                        <img src="images/teacher-info3.png" alt="" width="54" />
                      </a>
                    </li>
                  </ul>
                  <a href="javascript:void(0)" className="btn btn-secondary solid btn-sm no-shadow d-block">Status: {student?.user_id?.status}</a>
                </div>
                <div className="about-teacher">
                  {/* <h4>About:</h4>
                  <p>
                    Lorem ipsum dolor sit, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  </p> */}
                  <h4>Gender:</h4>
                  <p>{student?.gender_avatar?.name}</p>
                  {/* <h4>Date of birth</h4>
                  <p>2 Jan 2003</p> */}
                  <h4>Email:</h4>
                  <p>{getMaskedText(student?.email,'email')}</p>
                  <h4>Parent’s Email:</h4>
                  <p>{getMaskedText(student?.guardian_email,'email')}</p>
                  {student?.facilitator_email ? <><h4>Facilitator Email:</h4>
                    <p>{getMaskedText(student?.facilitator_email,'email')}</p></> : null}
                  {/* {student?.subjects.legth>0&&<><h4>Subjects Interested:</h4>
                  <div className="tutor-subject mt-2">
                    <ul>
                      {student?.subjects.map(subject => <li>
                        {subject?.title}
                      </li>)}
                    </ul>
                  </div></>} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default SchoolStudentStat
