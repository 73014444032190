import React, { useState, useEffect } from 'react'
import {
  Container,
  Nav,
  Navbar,
  Col,
  Row,
  Button,
  Form,
  FormControl,
  InputGroup,
  Modal,
  DropdownButton,
  Dropdown
} from "react-bootstrap";
import { NavLink, Link, useParams } from "react-router-dom";
import AdminSideMenu from "./AdminSideMenu";
import { Admin } from '../../Services/Admin';
import { NotificationManager } from 'react-notifications';
import config from "./../../config";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { FecthData } from '../../Services/FetchData';
import { getResourceUrl } from '../../Services/CommanFunctions';
import NotificationUI from '../common/NotificationUI';
import { getMaskedText, removeField } from '../../helpers/utils';
import ActionButton from '../ActionButton';
import _ from 'lodash';

function SchoolAdminFacilitator() {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [school, setSchool] = useState({});
  const [facilitator, setFacilitator] = useState([]);
  const [index, setIndex] = useState(0)
  const [selectedFacilitator, selectFaclitator] = useState()
  const [error, setError] = useState();
  const [validated, setValidated] = useState(false);
  const user = JSON.parse(localStorage.getItem('user'))
  const { id } = useParams();
  const history = useHistory()
  const [filter, setFilter] = useState('')
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    phone: '',
    email: ''
  })

  const getFacilitatorOfSchool = () => {
    FecthData.getData('facilitator/school_wise_facilitator_list/' + id + '?page=' + 0 + '&filter=' + filter).then(
      (data) => {
        setFacilitator(data?.data)
        if (index)
          selectFaclitator(data?.data[index])
      },
      (error) => {
        setError(error.toString())
        NotificationManager.error(error.toString(), 'error!', 2000);
      }
    )
  }
  const submitForm = () => {
    FecthData.postJsonData({ ...formData, school_id: id }, 'facilitator/add_facilitator').then(
      (data) => {
        if (data?.data) {
          NotificationManager.success(data.message, 'success!', 2000)
          getFacilitatorOfSchool()
          handleClose()
        } else {
          NotificationManager.error(data.message)
          // setFieldError(data.errors.details)

        }
      }
    )
  }
  useEffect(() => {
    FecthData.getData('school/get_school_by_id/' + id).then(
      (data) => setSchool(data),
      (error) => {
        setError(error.toString())
        NotificationManager.error(error.toString(), 'error!', 2000);
      }
    )
  }, [id]);
  useEffect(() => {
    getFacilitatorOfSchool()
  }, [filter])
  const logout = () => {
    localStorage.clear()
    history.push('/admin')
  }
  const changeUserStatus = (userId, changedStatus) => {
    Admin.changeUserStatus({ user_id: userId, status: changedStatus }).then(res => {
      getFacilitatorOfSchool()
    })
  }
  return (
    <>
      <section style={{ height: '100%', width: 'auto' }}>
        <div className="dashboard-wrap">
          <AdminSideMenu />
          <div className="middle">
            <Row>
              <Col md={12}>
                <Form.Group className="form-group mb-2">
                  <Form.Control type="text" placeholder="Search" className="search-input" />
                  <Button variant="primary" type="submit" className="search-btn">
                    <img src="images/search-icon.png" alt="" width="22" />
                  </Button>
                </Form.Group>
              </Col>
            </Row>
            <div className="card">
              <Row>
                <Col md={6} className="mt-2">
                  <div className="d-flex align-items-center">
                    <div className="school-logo-small">
                      <img src={school?.data?.logo ? getResourceUrl(school?.data?.logo) : "default_logos/school-logo.png"} alt=" " />
                    </div>
                    <h4 className="text-muted mb-0">{school?.data?.school_name}</h4>
                  </div>
                </Col>
                <Col md={6} className="mt-4">
                  <div className="filters">
                    <ul>
                      <li>
                        {/* <a href="javascript:void(0)">Sort <img src="images/sort-icon.png" alt="" width="17" />
                        </a> */}
                        <Form.Select style={{ cursor: 'pointer' }} onChange={e => setFilter(e.target.value)}>
                          <option selected disabled>Sort</option>
                          <option value={'ASC'}>Alphabetic A-Z</option>
                          <option value={'DESC'}>Alphabetic Z-A</option>
                        </Form.Select>
                      </li>
                      <li>
                        {/* <a href="javascript:void(0)">Filter <img src="images/filter-icon.png" alt="" width="13" />
                        </a> */}
                        <Form.Select style={{ cursor: 'pointer' }} onChange={e => setFilter(e.target.value)}>
                          <option selected disabled>Filter</option>
                          <option value={'Last Modified'}>Last Modified</option>
                          <option value={'Oldest'}>Oldest</option>
                          <option value={'Newest'}>Newest</option>
                        </Form.Select>
                      </li>
                      {/* <li onClick={handleShow}>
                        <a href="javascript:void(0)" className='btn-primary'>Add <img src="images/add-icon.png" alt="" width="15" />
                        </a>
                      </li> */}
                    </ul>
                  </div>
                </Col>
                <Col md={12}>
                  <div className="video-category">
                    <ul>
                      <li>
                        <Link to={'/admin/school/students/' + id}>Students</Link>
                      </li>
                      <li>
                        <Link to={'/admin/school/tutor/' + id}>Tutors</Link>
                      </li>
                      <li className="active">
                        <Link to={'/admin/school/facilitator/' + id}>Facilitators</Link>
                      </li>
                      <li>
                        <Link to={'/admin/school/parent/' + id}>Parents/Guardians</Link>
                      </li>
                      <li>
                        <Link
                          to={'/admin/school/admin/' + id}
                        >School Admin</Link>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col md={12}>
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">ID</th>
                          {/* <th scope="col">Role</th> */}
                          <th scope="col">Email</th>
                          {/* <th scope="col">Edit</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {facilitator?.map((content, index) => (
                          <tr key={index} onClick={() => {
                            setIndex(index)
                            selectFaclitator(content)
                          }}>
                            <td>
                            {content?.facilitators_details[0] && content?.facilitators_details[0]?.display_logo == 'yes' && content?.facilitators_details[0]?.propic? <img src={getResourceUrl(content?.facilitators_details[0]?.propic)} alt="" width="44" className="rounded me-2" />
                              :<img src={'images/no_img.png'} alt="" width="44" className="rounded me-2" />}
                              {getMaskedText(content?.facilitators_details[0]?.name,'name')}
                            </td>
                            <td> {content?.facilitator_id}</td>
                            {/* <td>Facilitator</td> */}
                            <td>{getMaskedText(content?.facilitators_details[0]?.email,'email')}</td>
                            {/* <td><a className="btn btn-primary btn-sm" onClick={() => {
                              alert("work in progress")
                              //  history.push({ pathname: "/admin/user/edit", state: { ...content?.facilitators_details[0], facilitator_id: content?._id, id: content?.facilitator_id, user_role: 'facilitator', student_id: content?.student_id, _id: content?._id } })
                            }
                            }>Edit</a></td> */}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </Col>
              </Row>
            </div>

          </div>
          <div className="right">
            <div className="user-short-text">
            <NotificationUI/>
              {/* <div className="notification-bell">
                <img src="images/bell-icon.png" alt="" width="32" />
                <div className="noti-dot"></div>
              </div> */}
              <div className="user-short-profile">
                <div className="user-avatar">
                  {user?.propic && <img src={getResourceUrl(user?.propic)} width={'100%'} height={'100%'} alt="" />}{!user?.propic && <img src="images/no_profile_img.png" width={'100%'} height={'100%'} alt="" />}
                </div>
                <div className="user-short-name">
                  <h5>{user?.name} </h5>
                  <p>Status: {user?.status} | {user?.role}</p>
                </div>
                <Dropdown >
                  <Dropdown.Toggle drop='none' variant="">

                  </Dropdown.Toggle >
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={logout}
                    // href="#/action"
                    >Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                {/* <div className="drop-down">
                  <img src="images/drop-icon.png" alt="" width="10" />
                </div> */}
              </div>
            </div>
            {selectedFacilitator ? <div className="card">
              <div className="school-side-details">
                <div className="school-logo-circle">
                  <img src={selectedFacilitator?.facilitators_details[0]?.propic?getResourceUrl(selectedFacilitator?.facilitators_details[0]?.propic):"images/no_profile_img.png"} alt="" />
                </div>
                <div className="school-more-option">
                  <Dropdown>
                    <Dropdown.Toggle>
                      <img src="images/more-icon.png" alt="" width="20" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {/* <Dropdown.Item onClick={() => alert('work in progress')}>
                        <Link className={'dropdown-item'}>View Profile</Link>
                        {/* <Link to={{ pathname: "/admin-facilitator-profile", state: selectedFacilitator }}>View Profile</Link> 
                      </Dropdown.Item>
                      <Dropdown.Divider /> */}
                      <Dropdown.Item >
                        <Link  className={'dropdown-item'} to={{ pathname: "/admin/user/edit", state: { ...selectedFacilitator,
                        ...removeField(selectedFacilitator?.facilitators_details[0],['_id','user_role']),
                         id: selectedFacilitator?._id, user_sf_id:selectedFacilitator?.facilitator_id,
                           user_role: 'facilitator' } }}>Edit Profile</Link>
                      </Dropdown.Item>
                      {/* <Dropdown.Divider />
                      <Dropdown.Item href="#/action">Delete Profile</Dropdown.Item> */}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="school-name">
                  <h5>{getMaskedText(selectedFacilitator?.facilitators_details[0]?.name,'name')}</h5>
                  <h6>ID: {selectedFacilitator?._id}</h6>
                </div>
                <div className="teacher-contact-detail">
                  <ul>
                    <li>
                      <a href="javascript:void(0)">
                        <img src="images/teacher-info1.png" alt="" width="54" />
                      </a>
                    </li>
                    <li className='phone-li'>
                      <a href={`tel:${selectedFacilitator?.phone}`}>
                        <img src="images/teacher-info2.png" alt="" width="54" />
                      </a>
                      {selectedFacilitator?.facilitators_details[0]?.phone ? <span className='show-phone'>{getMaskedText(selectedFacilitator?.facilitators_details[0]?.phone,'phone')}</span> : <span className='show-phone'>Phone Not Available</span>}
                    </li>
                    <li>
                      <a href={`mailto:${selectedFacilitator?.facilitators_details[0]?.email}`}>
                        <img src="images/teacher-info3.png" alt="" width="54" />
                      </a>
                    </li>
                  </ul>
                  <a href="javascript:void(0)" className={`btn btn-${selectedFacilitator.facilitators_details[0].status == 'Active' ? 'primary' : 'secondary'} solid btn-sm no-shadow d-block`}>Status: {selectedFacilitator.facilitators_details[0].status}</a>
                </div>
                <div className="about-teacher">
                  {/* <h4>About:</h4>
                  <p>
                    Lorem ipsum dolor sit, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  </p> */}
                  {/* <h4>Experience:</h4>
                  <p>
                    Lorem ipsum dolor sit, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  </p> */}
                  {/* <h4>Gender:</h4>
                  <p>Female</p>
                  <h4>Date of birth</h4>
                  <p>2 Jan 2003</p> */}
                  <h4>Email:</h4>
                  <p>{getMaskedText(selectedFacilitator?.facilitators_details[0]?.email,'email')}</p>
                  {selectedFacilitator?.students?.map.length>0&&<><h4>Students:</h4>
                  <div className="tutor-subject mt-2">
                    <ul>
                      {selectedFacilitator?.students?.map(student => <li>{student?.name}</li>)}
                      {/* <li>
                        Dvide
                      </li>
                      <li>
                        Richerd
                      </li>
                      <li>
                        Piter
                      </li> */}
                    </ul>
                  </div></>}
                </div>
                {selectedFacilitator.facilitators_details[0].status == 'Inactive' ? <Row>
                  <Col md="6" className="text-center mt-4">
                    <Button className="px-4 btn-pill no-shadow" variant="primary" onClick={() => changeUserStatus(selectedFacilitator.facilitator_id, 'Active')}>Admit</Button>
                  </Col>
                  <Col md="6" className="text-center mt-4">
                    <Button className="px-4 btn-pill" variant="secondary" onClick={() => changeUserStatus(selectedFacilitator.facilitator_id, 'Inactive')}>Decline</Button>
                  </Col>
                </Row> : null}
              </div>
            </div> : null}
          </div>
        </div>
      </section >

      <Modal show={show} onHide={handleClose}
        size="md"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Facilitator</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={submitForm}>
            <div className="modal-form">
              <Row>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" placeholder="First Name*" value={formData.first_name} onChange={(e) => setFormData({ ...formData, first_name: e.target.value })}/>
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" placeholder="Last Name*" value={formData.last_name} onChange={(e) => setFormData({ ...formData, last_name: e.target.value })}/>
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" placeholder="Contact Number*" value={formData.phone} onChange={(e) => setFormData({ ...formData, phone: e.target.value })}/>
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="email" placeholder="Email*" value={formData.email} onChange={(e) => setFormData({ ...formData, email: e.target.value })}/>
                </Form.Group>
                <Col md="12" className="text-center mt-2">

                  <ActionButton className="px-4 btn btn-primary btn-pill" title={'Send Request'} type={'submit'} requiredFields={_.values(formData)}/>
                  {/* <Button className="px-4 btn-pill" variant="primary" onClick={submitForm}>Send Request</Button> */}
                </Col>
              </Row>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

    </>
  )
}

export default SchoolAdminFacilitator
