import React, { useState } from 'react'
import {
  Container,
  Nav,
  Navbar,
  Col,
  Row,
  Button,
  Form,
  FormControl,
  InputGroup,
  Tabs,
  Tab,
} from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";
import FacilitatorSideMenu from "./FacilitatorSideMenu";
import Logout from '../logout';
import { School } from '../../Services/School';
import { NotificationManager } from 'react-notifications';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import FieldErrors from '../../Services/FieldErrors';
import NotificationUI from '../common/NotificationUI';
import { getMaskedText } from '../../helpers/utils';
import { getResourceUrl } from '../../Services/CommanFunctions';

function FacilitatorSettings() {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')))
  const [masked,doMask]=useState(true)
  const [error, setError] = useState()

  const selectedDay = val => {
    console.log(val);
  };
  const [password, setPassword] = useState({
    password: '',
    confirm_password: ''
  })
  let history = useHistory()

  const submit = (e) => {
    e.preventDefault()
    user.id = user?.facilitator?._id
    // user.facilitator_id = user?.facilitator?._id
    user.update_by_facilitator=true;
    School.post(user, "facilitator/update_facilitator").then(
      (data) => {
        if (data?.data) {

          NotificationManager.success(data.message, 'success!', 2000)
          user.facilitator = data?.data
          localStorage.setItem('user', JSON.stringify(user))
          window.location.reload()
        } else {
          NotificationManager.error(data.message, 'error!', 2000)
          setError(data.errors.details)
        }
      }
    )
  }
  const changePassword = (e) => {
    e.preventDefault()
    School.post({ ...password, _id: user._id }, "users/change_password").then(
      (data) => {
        if (data?.data) {
          NotificationManager.success(data.message, 'success!', 2000)
        } else {
          NotificationManager.error(data.message, 'error!', 2000)
          setError(data.errors.details)
        }
      }
    )
  }

  return (
    <>
      <section style={{ height: '100%', width: 'auto' }}>
        <div className="dashboard-wrap">
          <FacilitatorSideMenu />
          <div className="middle">
            <Row>
              <Col md={12}>
                <Form.Group className="form-group mb-2">
                  <Form.Control type="text" placeholder="Search" className="search-input" />
                  <Button variant="primary" type="submit" className="search-btn">
                    <img src="images/search-icon.png" alt="" width="22" />
                  </Button>
                </Form.Group>
              </Col>
              <Col md={12}>
                <div className="card">
                  <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3">
                    <Tab eventKey="home" title="Account Settings">
                      <div className="account-setting">
                        <h5 className="text-muted">Basic Information</h5>
                        <Form onSubmit={submit}>
                        <Form.Group className="mb-3" >
                                <Form.Label>Disable masking to edit</Form.Label>
                                <Form.Check type="switch" defaultChecked value={masked} onChange={(e)=>doMask(e.target.checked)} />
                                {/* <FieldErrors errors={fieldError} field="name" /> */}
                              </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" value={masked?getMaskedText(user?.name,'name'):user?.name} onChange={e => setUser({ ...user, name: e.target.value })} disabled={masked}/>
                            <FieldErrors errors={error} field="name" />
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" value={masked?getMaskedText(user?.email,'email'):user?.email} onChange={e => setUser({ ...user, email: e.target.value })} disabled={masked}/>
                            <FieldErrors errors={error} field="email" />
                          </Form.Group>
                          <Row>
                            {/* <Col md={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Gender</Form.Label>
                                <Form.Select aria-label="Default select example">
                                  <option value="1">Male</option>
                                  <option selected value="2">Female</option>
                                </Form.Select>
                              </Form.Group>
                            </Col> */}
                            <Col md={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Mobile Number</Form.Label>
                                <Form.Control type="tel" value={masked?getMaskedText(user?.phone,'phone'):user?.phone} onChange={e => setUser({ ...user, phone: e.target.value })} disabled={masked}/>
                                <FieldErrors errors={error} field="phone" />
                              </Form.Group>
                            </Col>
                            <Col md={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Address</Form.Label>
                                <Form.Control type="tel" value={user?.address} onChange={e => setUser({ ...user, address: e.target.value })} />
                                <FieldErrors errors={error} field="address" />
                              </Form.Group>
                            </Col>
                            <Col md={6}>
                                                    <Form.Group className="mb-3">
                              <Form.Label>Timezone</Form.Label>
                              <Form.Select name="timezone" onChange={e=>setUser({ ...user, timezone: e.target.value })} defaultValue={user?.timezone}>
                                <option disabled value={''}>Select Time Zone*</option>
                                <option value="EST">EST</option>
                                <option value="CST">CST</option>
                                <option value="PST">PST</option>
                              </Form.Select>
                              <FieldErrors errors={error} field="timezone" />
                            </Form.Group>
                            </Col>
                          </Row>
                          {/* <hr />
                          <h5 className="text-muted">Accessbility</h5>
                          <div className="accessbility mb-3">
                            <Form.Check
                              type="checkbox"
                              id="one"
                              label="Get notifications"
                              checked
                            />
                            <Form.Check
                              type="checkbox"
                              id="two"
                              label="Get facilitation requests"
                            />
                            <Form.Check
                              type="checkbox"
                              id="three"
                              label="Use chat feature"
                            />
                          </div> */}
                          <Button variant="primary" className="btn-pill" type="submit">
                            Save Changes
                          </Button>
                        </Form>
                        {/* <hr />
                        <h5 className="text-muted">Delete Accounts</h5>
                        <Button variant="danger" type="submit" className="mt-3 btn-pill" style={{ width: 200, }}>
                          Delete Account
                        </Button> */}
                      </div>
                    </Tab>
                    <Tab eventKey="profile" title="Privacy Settings">
                      <div className="account-setting">
                        <Form onSubmit={changePassword}>
                          <h5 className="text-muted">Change Password</h5>
                          {/* <Form.Group className="mb-3">
                            <Form.Label>Old Password</Form.Label>
                            <Form.Control type="text" value="Jane Doe" />
                          </Form.Group> */}
                          <Form.Group className="mb-3">
                            <Form.Label>New Password</Form.Label>
                            <Form.Control type="password" value={password.password} onChange={e => setPassword({ ...password, password: e.target.value })} />
                            <FieldErrors errors={error} field='password' />
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Label>Confirm Password</Form.Label>
                            <Form.Control type="password" value={password.confirm_password} onChange={e => setPassword({ ...password, confirm_password: e.target.value })} />
                            <FieldErrors errors={error} field='confirm_password' />
                          </Form.Group>
                          <Button variant="primary" type="submit">
                            Save Changes
                          </Button>
                        </Form>
                      </div>
                    </Tab>
                  </Tabs>
                </div>
              </Col>
            </Row>
          </div>
          <div className="right">
            <div className="user-short-text">
            <NotificationUI/>
              {/* <div className="notification-bell">
                <img src="images/bell-icon.png" alt="" width="32" />
                <div className="noti-dot"></div>
              </div> */}
              <div className="user-short-profile">
                <div className="user-avatar">
                  <img src={user?.propic ? getResourceUrl(user?.propic) : "images/no_profile_img.png"} width={'100%'} height={'100%'} alt="" />
                </div>
                <div className="user-short-name">
                  <h5>{user?.name} | ID: 567872</h5>
                  <p>Status: {user?.status}</p>
                </div>
                <Logout url={'/facilitator'} />
                {/* <div className="drop-down">
                  <img src="images/drop-icon.png" alt="" width="10" />
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default FacilitatorSettings
