import React, { useState } from 'react'
import {
  Container,
  Nav,
  Navbar,
  Col,
  Row,
  Button,
  Form,
  FormControl,
  InputGroup,
  Tabs,
  Tab,
  Dropdown,
} from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";
import SideMenu from "./SideMenu";
import { NotificationManager } from 'react-notifications';
import { Student } from '../../Services/Student';
import { Admin } from '../../Services/Admin';
import FieldErrors from '../../Services/FieldErrors';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import config from '../../config';
import { getResourceUrl } from '../../Services/CommanFunctions';
import NotificationUI from '../common/NotificationUI';
import { getMaskedText } from '../../helpers/utils';

function Settings() {
  const user = JSON.parse(localStorage.getItem('user'))
  const history = useHistory()
  const [masked,doMask]=useState(true)
  const [formData, setFormData] = useState({
    id: user?.student?._id,
    name: user?.name,
    first_name: user?.student?.first_name,
    last_name: user?.student?.last_name,
    phone: user?.phone,
    email: user?.email,
    guardian_email: user?.student?.guardian_email,
    facilitator_email:user?.student?.facilitator_email,
    grade_id: user?.student?.grade_id?._id,
    gender_id: user?.student?.gender_avatar?._id,
    academic_year: user?.student?.academic_year,
    school_id: user?.student?.school_id,
    student_code: user?.student?.student_code,
    status: user?.status,
    require_facilitator: user?.student?.require_facilitator,
    get_notification: user?.student?.get_notification,
    use_chat_feature: user?.student?.use_chat_feature,
    timezone:user?.timezone,
  })
  const [fieldError, setFieldError] = useState()
  const [password, setPassword] = useState({
    password: '',
    confirm_password: '',
    error: null
  })
  const changeName = e => {
    e.preventDefault()
    const fullName = e.target.value.split(' ')
    setFormData({ ...formData, name: e.target.value, first_name: fullName[0], last_name: fullName[1] })
  }
  const formSubmit = e => {
    e.preventDefault()
    // 
    Student.update(formData, 'student/update_student').then(
      (data) => {
        ;
        if (data?.data) {
          NotificationManager.success(data.message, 'success!', 2000);
          setFieldError(null)
          const fullName = formData?.name?.split(' ')
          user.name = formData?.name
          user.phone = data?.data?.phone
          user.email = data?.data?.email
          user.student = data?.data
          user.timezone=formData?.timezone
          localStorage.setItem('user', JSON.stringify(user))
          window.location.reload()
          // setSubject(data.data)
        } else {
          setFieldError(data.errors.details)
        }

      },
      (error) => {
        NotificationManager.error(error.toString(), 'error!', 2000);
        // setError(error)
      }
    )
  }
  const changePassword = (e) => {
    e.preventDefault()
    Admin.post({ ...password, _id: user._id }, "users/change_password").then(
      // (data) => {
      //     if (data?.data) {
      //         // localStorage.setItem('user', JSON.stringify(data?.data))
      //     }
      // }
      (data) => {
        if (!data.errors) {

          NotificationManager.success(data.message, 'success!', 2000);
          // setMessage(data.message)
          // history.push('/admin-dashboard');
        }
        else {
          // NotificationManager.error(data.message, 'error!', 2000);
          setPassword({ ...password, error: data.errors.details })

          // FieldErrors(data)
          // < fieldErrors errors = { data } />
        }

      }
    )


  }
  const selectedDay = val => {
    console.log(val);
  };
  const logout = (url) => {
    localStorage.clear()
    history.push(url)
  }
  return (
    <>
      <section style={{ height: '100%', width: 'auto' }}>
        <div className="dashboard-wrap">
          <SideMenu />
          <div className="middle">
            <Row>
              <Col md={12}>
                <Form.Group className="form-group mb-2">
                  <Form.Control type="text" placeholder="Search" className="search-input" />
                  <Button variant="primary" type="submit" className="search-btn">
                    <img src="images/search-icon.png" alt="" width="22" />
                  </Button>
                </Form.Group>
              </Col>
              <Col md={12}>
                <div className="card">
                  <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3">
                    <Tab eventKey="home" title="Account Settings">
                      <div className="account-setting">
                        <h5 className="text-muted">Basic Information</h5>
                        <Form onSubmit={formSubmit}>
                          <Form.Group className="mb-3" >
                                <Form.Label>Disable masking to edit</Form.Label>
                                <Form.Check type="switch" defaultChecked value={masked} onChange={(e)=>doMask(e.target.checked)} />
                                {/* <FieldErrors errors={fieldError} field="name" /> */}
                              </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" value={masked?getMaskedText(formData?.name,'name'):formData?.name} onChange={changeName} disabled={masked}/>
                            <FieldErrors errors={fieldError} field="name" />
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" value={masked?getMaskedText(formData?.email,'email'):formData?.email} onChange={e => setFormData({ ...formData, email: e.target.value })} disabled={masked}/>
                            <FieldErrors errors={fieldError} field="email" />                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Label>Parents/Guardian Email</Form.Label>
                            <Form.Control type="email" value={masked?getMaskedText(formData?.guardian_email,'email'):formData?.guardian_email} onChange={e => setFormData({ ...formData, guardian_email: e.target.value })} disabled={masked}/>
                            <FieldErrors errors={fieldError} field="guardian_email" />
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Label>Facilitator Email</Form.Label>
                            <Form.Control type="email" value={masked?getMaskedText(formData?.facilitator_email,'email'):formData?.facilitator_email} onChange={e => setFormData({ ...formData, facilitator_email: e.target.value })} disabled={masked}/>
                            <FieldErrors errors={fieldError} field="facilitator_email" />
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Label>Mobile Number</Form.Label>
                            <Form.Control type="tel" value={masked?getMaskedText(formData?.phone,'phone'):formData?.phone} onChange={e => setFormData({ ...formData, phone: e.target.value })} disabled={masked}/>
                            <FieldErrors errors={fieldError} field="phone" />
                          </Form.Group>
                          <Form.Group className="mb-3">
                              <Form.Label>Timezone</Form.Label>
                              <Form.Select name="timezone" onChange={e=>setFormData({ ...formData, timezone: e.target.value })} defaultValue={formData?.timezone}>
                                <option disabled value={''}>Select Time Zone*</option>
                                <option value="EST">EST</option>
                                <option value="CST">CST</option>
                                <option value="PST">PST</option>
                              </Form.Select>
                              <FieldErrors errors={fieldError} field="timezone" />
                            </Form.Group>
                          <h5 className="text-muted">Accessbility</h5>
                          <div className="accessbility mb-3">
                            <Form.Check
                              type="checkbox"
                              id="one"
                              label="Request a facilitator"
                              checked={formData?.require_facilitator == 'yes'}
                              onChange={e => setFormData({ ...formData, require_facilitator: e.target.checked ? 'yes' : 'no' })}
                            />
                            <Form.Check
                              type="checkbox"
                              id="two"
                              label="Get notifications"
                              checked={formData?.get_notification}
                              onChange={e => setFormData({ ...formData, get_notification: e.target.checked })}
                            />
                            <Form.Check
                              type="checkbox"
                              id="three"
                              label="Use chat feature"
                              checked={formData?.use_chat_feature}
                              onChange={e => setFormData({ ...formData, use_chat_feature: e.target.checked })}
                            />
                          </div>
                          {/* <Row>
                            <Col md={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Gender</Form.Label>
                                <Form.Select aria-label="Default select example" defaultValue={formData?.gender_id} onChange={e => setFormData({ ...formData, gender_id: e.target.value })}>
                                  <option value="1">Male</option>
                                  <option value="2">Female</option>
                                </Form.Select>
                                <FieldErrors errors={fieldError} field="gender_id" />
                              </Form.Group>
                            </Col>
                            <Col md={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Mobile Number</Form.Label>
                                <Form.Control type="tel" value={formData?.phone} onChange={e => setFormData({ ...formData, phone: e.target.value })} />
                                <FieldErrors errors={fieldError} field="phone" />
                              </Form.Group>
                            </Col>
                            <Col md={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>School</Form.Label>
                                <Form.Select aria-label="Default select example" defaultValue={formData?.school_id} onChange={e => setFormData({ ...formData, school_id: e.target.value })}>
                                  <option value="1">Mather High School, IL</option>
                                  <option value="2">Marion High School, IL</option>
                                  <option value="3">Don Bosco School</option>
                                </Form.Select>
                                <FieldErrors errors={fieldError} field="school_id" />
                              </Form.Group>
                            </Col>
                            <Col md={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Grade</Form.Label>
                                <Form.Select aria-label="Default select example" defaultValue={formData?.grade_id} onChange={e => setFormData({ ...formData, grade_id: e.target.value })}>
                                  <option value="1">4th Grade</option>
                                  <option value="2">8th Grade</option>
                                </Form.Select>
                                <FieldErrors errors={fieldError} field="grade_id" />
                              </Form.Group>
                            </Col>
                          </Row> */}
                          <Button variant="primary" type="submit">
                            Save Changes
                          </Button>
                        </Form>
                        {/* <hr />
                        <h5 className="text-muted">Accessbility</h5>
                        <Form>
                          <div className="accessbility mb-3">
                            <Form.Check
                              type="checkbox"
                              id="one"
                              label="Request a facilitator"
                              checked
                            />
                            <Form.Check
                              type="checkbox"
                              id="two"
                              label="Get notifications"
                            />
                            <Form.Check
                              type="checkbox"
                              id="three"
                              label="Use chat feature"
                            />
                            <Button variant="primary" type="submit" disabled className="mt-3">
                              Save Changes
                            </Button>
                          </div>
                        </Form> */}
                        {/* <hr />
                        <h5 className="text-muted">Delete Accounts</h5>
                        <Button variant="danger" type="submit" className="mt-3" style={{ width: 200, }}>
                          Delete Your Account
                        </Button> */}
                      </div>
                    </Tab>
                    <Tab eventKey="profile" title="Privacy Settings">
                      <div className="account-setting">
                        <Form onSubmit={changePassword}>
                          <h5 className="text-muted">Change Password</h5>
                          {/* <Form.Group className="mb-3">
                            <Form.Label>Old Password</Form.Label>
                            <Form.Control type="text" value="Jane Doe" />
                          </Form.Group> */}
                          <Form.Group className="mb-3">
                            <Form.Label>New Password</Form.Label>
                            <Form.Control type="password" value={password?.password} onChange={e => setPassword({ ...password, password: e.target.value })} />
                            <FieldErrors errors={password.error} field="password" />
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Label>Confirm Password</Form.Label>
                            <Form.Control type="password" value={password?.confirm_password} onChange={e => setPassword({ ...password, confirm_password: e.target.value })} />
                            <FieldErrors errors={password.error} field="confirm_password" />
                          </Form.Group>
                          <Button variant="primary" type="submit">
                            Save Changes
                          </Button>
                        </Form>
                      </div>
                    </Tab>
                  </Tabs>
                </div>
              </Col>
            </Row>
          </div>
          <div className="right">
            <div className="user-short-text">
            <NotificationUI/>
              {/* <div className="notification-bell">
                <img src="images/bell-icon.png" alt="" width="32" />
                <div className="noti-dot"></div>
              </div> */}
              <div className="user-short-profile">
                <div className="user-avatar">
                  {user?.student?.gender_avatar?.icon_image && <img src={getResourceUrl(user?.student?.gender_avatar?.icon_image)} width={'100%'} height={'100%'} alt="" />}{!user?.student?.gender_avatar?.icon_image && <img src="images/avatar2.png" width={'100%'} height={'100%'} alt="" />}
                </div>
                <div className="user-short-name">
                  <h5>{user?.name} - {user?.student_profile?.school_name}</h5>
                  <p><small>ID: {user?.student?.student_code}</small></p>
                  <p>Academic Year: {user?.student?.academic_year}</p>
                </div>
                <Dropdown >
                  <Dropdown.Toggle drop='none' variant="">

                  </Dropdown.Toggle >
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => logout('/')}
                    // href="#/action"
                    >Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                {/* <div className="drop-down">
                  <img src="images/drop-icon.png" alt="" width="10" />
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default Settings
